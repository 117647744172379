.fullPageSpinnerModule {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0.99;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff
}

.fpsm_loadingAnimationContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center
}

.fpsm_loader {
    border-radius: 50%;
    width: 89px;
    height: 89px;
    animation: loadingScreenSpin 2s linear infinite
}

.fpsm_loadingText {
    font-size: 14px;
    font-family: ITCAvantGardeGothic, Trebuchet MS, Helvetica, sans-serif;
    padding-top: 20px
}

@keyframes loadingScreenSpin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.fullPageSpinnerModuleOnDrag {
    width: 100%;
    height: 100%;
}
.fpsm_loadingAnimationContainerOnDrag {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}