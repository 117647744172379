@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;0,800;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;

  /* Global values */
  -webkit-text-stroke: inherit;
  -webkit-text-stroke: initial;
  -webkit-text-stroke: unset;
  text-stroke: inherit;
  text-stroke: initial;
  text-stroke: unset;
  font-family: 'Open Sans', sans-serif;
}

/* .hero-text {
    -webkit-text-stroke: 1px #ffcc00;
    text-stroke: 1px #ffcc00;
} */

/* .text-gradient {
    background: radial-gradient(
        64.18% 64.18% at 71.16% 35.69%,
        #def9fa 0.89%,
        #bef3f5 17.23%,
        #9dedf0 42.04%,
        #7de7eb 55.12%,
        #5ce1e6 71.54%,
        #33bbcf 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
} */

/* .bg-blue-gradient {
    background: linear-gradient(
        157.81deg,
        #def9fa -43.27%,
        #bef3f5 -21.24%,
        #9dedf0 12.19%,
        #7de7eb 29.82%,
        #5ce1e6 51.94%,
        #33bbcf 90.29%
    );
} */

/* .bg-place-badge {
  background: url('https://hertz.com/assets/placeBadge.svg');
} */

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.truncate-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#cookie-button {
  position: static;
  color: rgb(227, 178, 102);
  text-decoration: underline;
}

/* Cookie banner styles */
.cc-window.cc-banner.cc-type-opt-in.cc-theme-block.cc-bottom.cc-color-override--2095332693 {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 20px 40px;
}

a.cc-btn.cc-allow {
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-color: black;
}
a.cc-btn.cc-dismiss {
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-color: black;
}

a#securitiCmpCookiePrefBtn {
  background: white;
}
